.App {
    text-align: center;
    overflow: hidden; /* Prevents the entire page from scrolling */
}

body {
    font-family: "Besley", serif;
}

.transition-colors {
    transition: background-color 0.5s ease, color 0.5s ease;
}

.blur-xs,
.blur-sm {
    transition: filter 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



.blur-xs {
    filter: blur(1.8px);
}

.blur-sm {
    filter: blur(4px);
}

* {
    cursor: none !important;
}

.reach-out-text {
    border-bottom: 2px dotted #4caf50;
    cursor: pointer;
    transition: all 0.3s ease;
}

.custom-cursor-clickable {
    cursor: none !important;
}

.reach-out-text:hover {
    background-color: rgba(76, 175, 80, 0.1);
}

.blur-background {
    filter: blur(5px);
    transition: filter 0.3s ease;
}

.backdrop-blur {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.dark-mode-backdrop {
    background-color: rgba(24, 24, 24, 0.95);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    body .custom-cursor,
    body .custom-cursor-clickable,
    body *,
    body a,
    body button,
    body [role="button"],
    body input[type="submit"],
    body input[type="reset"],
    body input[type="button"] {
        cursor: auto !important;
    }
}

/* Add these new styles */
.page-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.page-transition-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.theme-transition {
    transition: 
        background-color 0.5s ease-in-out,
        color 0.5s ease-in-out,
        border-color 0.5s ease-in-out,
        box-shadow 0.5s ease-in-out !important;
}

.theme-transition * {
    transition: 
        background-color 0.5s ease-in-out,
        color 0.5s ease-in-out,
        border-color 0.5s ease-in-out,
        box-shadow 0.5s ease-in-out !important;
}

/* Ensure the chat area handles its own scrolling */
.chat-container {
    overflow-y: auto;
    max-height: 100vh; /* Adjust as needed */
}
