@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add these cursor styles near the top of the file */
* {
  cursor: none !important; /* Force no cursor on all elements */
}

/* Ensure cursor remains hidden even on interactive elements */
button, 
a, 
input, 
textarea, 
select, 
[role="button"],
.custom-cursor-clickable {
  cursor: none !important;
}

/* Hide cursor when outside viewport */
html {
  cursor: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown-content {
  /* Basic markdown styles */
  & p {
    margin-bottom: 0.5rem;
  }
  
  & strong {
    font-weight: 600;
  }
  
  & em {
    font-style: italic;
  }
  
  & code {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }
  
  & pre {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1em;
    border-radius: 5px;
    overflow-x: auto;
  }
  
  & ul, & ol {
    margin-left: 1.5em;
    margin-bottom: 0.5rem;
  }
  
  & li {
    margin-bottom: 0.25rem;
  }
}

/* Add these styles for markdown content links */
.markdown-content a {
  color: #3b82f6; /* Tailwind's blue-500 */
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

.markdown-content a:hover {
  color: #2563eb; /* Tailwind's blue-600 */
}

/* For dark mode */
.theme-transition .markdown-content a {
  color: #60a5fa; /* Tailwind's blue-400 - lighter blue for dark mode */
}

.theme-transition .markdown-content a:hover {
  color: #93c5fd; /* Tailwind's blue-300 */
}

/* Update the cursor styles to only apply when no-cursor class is present */
.no-cursor * {
  cursor: none !important;
}

.no-cursor button, 
.no-cursor a, 
.no-cursor input, 
.no-cursor textarea, 
.no-cursor select, 
.no-cursor [role="button"],
.no-cursor .custom-cursor-clickable {
  cursor: none !important;
}

.no-cursor html,
.no-cursor body {
  cursor: none !important;
}
